import React from 'react'

export default function PrivacyPolicy() {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>
        Privacy Policy
        Last Updated: March 11, 2025
        Welcome to Nub.lol! This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our React.js application (the "App"), which provides meme-related content. By using the App, you agree to the terms outlined in this Privacy Policy. If you do not agree, please do not use the App.
        1. Information We Collect
        We may collect the following types of information:
        a. Personal Information
        User-Provided Information: If you create an account or contact us, we may collect details such as your name, email address, or username.

        Automatically Collected Information: We may collect device information (e.g., IP address, browser type, operating system), usage data (e.g., pages visited, time spent on the App), and cookies or similar tracking technologies.

        b. Non-Personal Information
        Aggregated or anonymized data (e.g., general usage statistics) that does not identify you personally.

        c. Content You Share
        If you upload or share memes, comments, or other content, we collect and store that information as part of the App’s functionality.

        2. How We Use Your Information
        We use the information we collect to:
        Provide, maintain, and improve the App’s features, such as displaying memes and enabling user interactions.

        Personalize your experience (e.g., recommending memes based on your activity).

        Respond to your inquiries or support requests.

        Analyze usage trends to enhance the App.

        Comply with legal obligations or protect our rights.

        3. How We Share Your Information
        We do not sell your personal information. We may share it in the following cases:
        Service Providers: With third-party vendors (e.g., hosting services, analytics providers) who assist us in operating the App, under strict confidentiality agreements.

        Legal Requirements: If required by law, regulation, or legal process (e.g., a court order).

        Business Transfers: In connection with a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.

        With Your Consent: If you explicitly agree to share your information.

        4. Cookies and Tracking Technologies
        We use cookies and similar technologies to enhance your experience, track usage, and serve relevant content. You can manage cookie preferences through your browser settings, though disabling them may affect App functionality.
        5. Third-Party Links
        The App may contain links to external sites (e.g., meme sources or social media). We are not responsible for the privacy practices of those third parties. Please review their policies separately.
        6. Data Security
        We implement reasonable measures to protect your information from unauthorized access, loss, or misuse. However, no system is 100% secure, and we cannot guarantee absolute security.
        7. Your Choices
        Account Information: You may update or delete your account information by [describe process, e.g., "contacting us at support@[yourdomain].com"].

        Opt-Out: You can opt out of promotional emails by following the unsubscribe instructions in those messages.

        8. Children’s Privacy
        The App is not intended for users under 13 years of age. We do not knowingly collect personal information from children under 13. If we learn such data has been collected, we will take steps to delete it.
        9. International Users
        The App is hosted in [specify hosting location, e.g., "the United States"]. If you access it from outside this region, your data may be transferred internationally, subject to applicable laws.
        10. Changes to This Privacy Policy
        We may update this Privacy Policy from time to time. Changes will be posted here with an updated "Last Updated" date. Your continued use of the App after changes constitutes acceptance of the revised policy.
        11. Contact Us
        If you have questions about this Privacy Policy, please contact us at:
        Email: [gwardobiz@gmail.com (mailto:gwardobiz@gmail.com)]
      </p>
    </div>
  )
}
