import './App.css';
import { useState } from "react";
import { Routes, Route } from "react-router-dom";

import Navbar from './components/Navbar';
import Nubmemes from './components/Nubmemes';
import Portal from './components/Portal';
import Edit from './components/Edit';
import Generator from './components/Generator';
import Humper from './components/Humper';
import NubAI from './components/NubAI';
import NubBeta from './components/NubBeta';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  const [refresh, setRefresh] = useState(false);
  return (
    <div className="App">
      <Navbar
        setMemes={(x) => setRefresh(true)}
      />

      <Routes>
        <Route path="/" element={<Nubmemes setRefresh={(x) => setRefresh(x)} refresh={refresh} />} />
        <Route path="/ai" element={<NubAI setRefresh={(x) => setRefresh(x)} refresh={refresh} />} />
        <Route path="/beta" element={<NubBeta setRefresh={(x) => setRefresh(x)} refresh={refresh} />} />
        <Route path="/leon-super-secret-portal" element={<Portal />} />
        <Route path="/leon-super-secret-portal-2" element={<Edit />} />
        <Route path="/generator" element={<Generator />} />
        <Route path="/hump-me" element={<Humper />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="/game" element={<Game />} /> */}
      </Routes>
    </div>
  );
}

export default App;
